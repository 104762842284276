import { ReactElement } from 'react'
import styled from 'styled-components/macro'

import SantaHat from '../../assets/images/santa-hat.png'

const SantaHatImage = styled.img`
  position: absolute;
  top: -3px;
  right: 11px;
  height: 18px;
`

const Christmas = <SantaHatImage src={SantaHat} alt="Santa hat" />

const DATE_TO_ORNAMENT: { [date: string]: ReactElement } = {
  '12-1': Christmas,
  '12-2': Christmas,
  '12-3': Christmas,
  '12-4': Christmas,
  '12-5': Christmas,
  '12-6': Christmas,
  '12-7': Christmas,
  '12-8': Christmas,
  '12-9': Christmas,
  '12-10': Christmas,
  '12-11': Christmas,
  '12-12': Christmas,
  '12-13': Christmas,
  '12-14': Christmas,
  '12-15': Christmas,
  '12-16': Christmas,
  '12-17': Christmas,
  '12-18': Christmas,
  '12-19': Christmas,
  '12-20': Christmas,
  '12-21': Christmas,
  '12-22': Christmas,
  '12-23': Christmas,
  '12-24': Christmas,
  '12-25': Christmas,
  '12-26': Christmas,
  '12-27': Christmas,
  '12-28': Christmas,
  '12-29': Christmas,
  '12-30': Christmas,
  '12-31': Christmas,
  '1-1': Christmas,
  '1-2': Christmas,
  '1-3': Christmas,
  '1-4': Christmas,
  '1-5': Christmas,
}

const HolidayOrnament = () => {
  // months in javascript are 0 indexed...
  const today = `${new Date().getMonth() + 1}-${new Date().getDate()}`
  return DATE_TO_ORNAMENT[today] || null
}

export default HolidayOrnament
