import { Trans } from '@lingui/macro'
import { Percent } from '@uniswap/sdk-core'
import { log } from 'console'
import { MouseEventHandler } from 'react'
import styled from 'styled-components/macro'

import { ThemedText } from '../../theme'
import { RowBetween, RowFixed } from '../Row'
import SettingsTab from '../Settings'
import { TAB_NAMES } from './TabNames'

const StyledSwapHeader = styled.div`
  padding: 1rem 1.25rem 0.5rem 1.25rem;
  width: 100%;
  color: ${({ theme }) => theme.deprecated_text2};
`
const TabButton = styled.div`
  border: 1px solid #55555500;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 0px;
  font-size: 16px;
  wont-weight: 500;
  cursor: pointer;
  transition: all 0.1s;

  :hover {
    border: 1px solid #1966d4;
    border-radius: 16px;
  }
`
const TabButtonActive = styled.div`
  border: 1px solid #55555500;
  // border-bottom: 1px solid #1966d4;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 16px;
  font-size: 16px;
  wont-weight: 500;
  cursor: pointer;
  background: #1966d4;
  color: white;
`
const Temp = styled.div`
  color: white;
`

export default function SwapHeader({
  allowedSlippage,
  selectedTab,
  activeTab,
}: {
  allowedSlippage: Percent
  selectedTab: any
  activeTab: string
}) {
  return (
    <StyledSwapHeader>
      <RowBetween>
        <RowFixed>
          <Temp>Swap</Temp>
          {/* LIMIT ORDERS TABS */}
          {/* {Object.values(TAB_NAMES).map((tab) =>
            activeTab == tab ? (
              <TabButtonActive onClick={() => selectedTab(tab)} key={tab}>
                {tab}
              </TabButtonActive>
            ) : (
              <TabButton onClick={() => selectedTab(tab)} key={tab}>
                {tab}
              </TabButton>
            )
          )} */}
          {/* {activeTab == TAB_NAMES.swap ? (
            <TabButtonActive onClick={() => selectedTab(TAB_NAMES.swap)}>
              <Trans>{TAB_NAMES.swap}</Trans>
            </TabButtonActive>
          ) : (
            <TabButton onClick={() => selectedTab(TAB_NAMES.swap)}>
              <Trans>{TAB_NAMES.swap}</Trans>
            </TabButton>
          )}
          {activeTab == TAB_NAMES.limit ? (
            <TabButtonActive onClick={() => selectedTab(TAB_NAMES.limit)}>
              <Trans>{TAB_NAMES.limit}</Trans>
            </TabButtonActive>
          ) : (
            <TabButton onClick={() => selectedTab(TAB_NAMES.limit)}>
              <Trans>{TAB_NAMES.limit}</Trans>
            </TabButton>
          )} */}
        </RowFixed>
        {activeTab == TAB_NAMES.swap ? (
          <RowFixed>
            <SettingsTab placeholderSlippage={allowedSlippage} />
          </RowFixed>
        ) : null}
      </RowBetween>
    </StyledSwapHeader>
  )
}
