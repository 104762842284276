import { Currency, CurrencyAmount, Percent, TradeType } from '@uniswap/sdk-core'
import { GRAPH } from 'components/LimitOrder/lib/Consts'
import { IOrder } from 'components/LimitOrder/lib/interfaces/IOrder'
// import { isAddress } from 'ethers/lib/utils'
import { isAddress } from '../../utils'
import { TOKEN_SHORTHANDS } from '../../constants/tokens'

import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { Field, replaceLimitOrderState, selectCurrency, setRecipient, switchCurrencies, typeInput } from './actions'
import { LimitOrderState } from './reducer'
import { ParsedQs } from 'qs'
import { InterfaceTrade, TradeState } from 'state/routing/types'
import { useWeb3React } from '@web3-react/core'
import { AppState } from '../index'
import { useCurrency } from 'hooks/Tokens'
import useENS from 'hooks/useENS'
import { useCurrencyBalances } from 'lib/hooks/useCurrencyBalance'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { Trans } from '@lingui/macro'
import { ChainId } from '@uniswap/smart-order-router'
import useParsedQueryString from 'hooks/useParsedQueryString'

export function useLimitOrderState(): AppState['limitOrder'] {
  return useAppSelector((state) => state.limitOrder)
}

export function useDerivedLimitOrderInfo(): {
  currencies: { [field in Field]?: Currency | null }
  currencyBalances: { [field in Field]?: CurrencyAmount<Currency> }
  parsedAmount: CurrencyAmount<Currency> | undefined
  inputError?: ReactNode
  // trade: {
  //   trade: InterfaceTrade<Currency, Currency, TradeType> | undefined
  //   state: TradeState
  // }
} {
  const { account } = useWeb3React()

  const {
    independentField,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
    recipient,
  } = useLimitOrderState()

  const inputCurrency = useCurrency(inputCurrencyId)
  // console.log(inputCurrency)

  const outputCurrency = useCurrency(outputCurrencyId)

  const recipientLookup = useENS(recipient ?? undefined)
  const to: string | null = (recipient === null ? account : recipientLookup.address) ?? null

  const relevantTokenBalances = useCurrencyBalances(
    account ?? undefined,
    useMemo(() => [inputCurrency ?? undefined, outputCurrency ?? undefined], [inputCurrency, outputCurrency])
  )

  const isExactIn: boolean = independentField === Field.INPUT
  const parsedAmount = useMemo(
    () => tryParseCurrencyAmount(typedValue, (isExactIn ? inputCurrency : outputCurrency) ?? undefined),
    [inputCurrency, isExactIn, outputCurrency, typedValue]
  )

  // const trade = useBestTrade(
  //   isExactIn ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT,
  //   parsedAmount,
  //   (isExactIn ? outputCurrency : inputCurrency) ?? undefined
  // )

  const currencyBalances = useMemo(
    () => ({
      [Field.INPUT]: relevantTokenBalances[0],
      [Field.OUTPUT]: relevantTokenBalances[1],
    }),
    [relevantTokenBalances]
  )

  const currencies: { [field in Field]?: Currency | null } = useMemo(
    () => ({
      [Field.INPUT]: inputCurrency,
      [Field.OUTPUT]: outputCurrency,
    }),
    [inputCurrency, outputCurrency]
  )

  // allowed slippage is either auto slippage, or custom user defined slippage if auto slippage disabled
  // const autoSlippageTolerance = useAutoSlippageTolerance(trade.trade)
  // const allowedSlippage = useUserSlippageToleranceWithDefault(autoSlippageTolerance)

  const inputError = useMemo(() => {
    let inputError: ReactNode | undefined

    if (!account) {
      inputError = <Trans>Connect Wallet</Trans>
    }

    if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
      inputError = inputError ?? <Trans>Select a token</Trans>
    }

    if (!parsedAmount) {
      inputError = inputError ?? <Trans>Enter an amount</Trans>
    }

    const formattedTo = isAddress(to)
    if (!to || !formattedTo) {
      inputError = inputError ?? <Trans>Enter a recipient</Trans>
    } else {
      // if (BAD_RECIPIENT_ADDRESSES[formattedTo]) {
      //   inputError = inputError ?? <Trans>Invalid recipient</Trans>
      // }
    }

    // compare input balance to max input based on version
    // const [balanceIn, amountIn] = [currencyBalances[Field.INPUT], trade.trade?.maximumAmountIn(allowedSlippage)]

    // if (balanceIn && amountIn && balanceIn.lessThan(amountIn)) {
    // inputError = <Trans>Insufficient {amountIn.currency.symbol} balance</Trans>
    // }

    return inputError
  }, [
    account,
    currencies,
    currencyBalances,
    parsedAmount,
    to,
    // , trade.trade
  ])

  return useMemo(
    () => ({
      currencies,
      currencyBalances,
      parsedAmount,
      inputError,
      // trade,
    }),
    [
      currencies,
      currencyBalances,
      parsedAmount,
      inputError,
      // , trade
    ]
  )
}

export function useLimitOrders() {
  const [orderState, setOrderState] = useState({ orders: [] } as { orders: IOrder[] })

  async function fetchUserOrders(account: string, chainId: number) {
    const query = `
    query GetOrdersByOwner($owner: String) {
      orders(where:{owner:$owner}) {
        id
        owner
        module
        inputToken
        outputToken
        inputAmount
        minReturn
        witness
        secret
        status
        blockNumber
        cancelledTxHash
        createdAt
        createdTxHash
        executedTxHash
        minReturn
        secret
        status
        updatedAt
        vault
      }
    }`
    try {
      const res = await fetch(GRAPH[chainId], {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query, variables: { owner: account.toLowerCase() } }),
      })

      const { data } = await res.json()
      return { orders: data.orders }
    } catch (e) {
      console.warn('Error loading orders from TheGraph', e)
      return { orders: [] }
    }
  }

  function getGraphOrders(account: string, chainId: number): { orders: IOrder[] } {
    // useEffect(() => {
    console.log(`Requesting load orders from the graph`)
    if (account && isAddress(account)) {
      fetchUserOrders(account, chainId).then((data: { orders: IOrder[] }) => {
        console.log(`Fetched ${data.orders.length}  orders from the graph`)
        let res = data.orders.sort((a: IOrder, b: IOrder) => (a.blockNumber < b.blockNumber ? 1 : 0))
        data.orders = res
        setOrderState(data)
      })
    }
    // }, [account, chainId])

    return orderState
  }

  return { getGraphOrders, orderState } //, selectInputToken, selectOutputToken }
}

// export function useLimitOrderMethods() {
//   const cancelOrder = useCallback((order: IOrder) => {
//     console.log(order)
//   }, [])

//   return [cancelOrder]
// }

export function useLimitOrderActionHandlers(): {
  onCurrencySelection: (field: Field, currency: Currency) => void
  onSwitchTokens: () => void
  onUserInput: (field: Field, typedValue: string) => void
  onChangeRecipient: (recipient: string | null) => void
} {
  const dispatch = useAppDispatch()
  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency) => {
      dispatch(
        selectCurrency({
          field,
          currencyId: currency.isToken ? currency.address : currency.isNative ? 'ETH' : '',
        })
      )
    },
    [dispatch]
  )

  const onSwitchTokens = useCallback(() => {
    dispatch(switchCurrencies())
  }, [dispatch])

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(typeInput({ field, typedValue }))
    },
    [dispatch]
  )

  const onChangeRecipient = useCallback(
    (recipient: string | null) => {
      dispatch(setRecipient({ recipient }))
    },
    [dispatch]
  )

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
  }
}

function parseCurrencyFromURLParameter(urlParam: ParsedQs[string]): string {
  if (typeof urlParam === 'string') {
    const valid = isAddress(urlParam)
    if (valid) return valid
    const upper = urlParam.toUpperCase()
    if (upper === 'ETH') return 'ETH'
    if (upper in TOKEN_SHORTHANDS) return upper
  }
  return ''
}
function parseTokenAmountURLParameter(urlParam: any): string {
  return typeof urlParam === 'string' && !isNaN(parseFloat(urlParam)) ? urlParam : ''
}

function parseIndependentFieldURLParameter(urlParam: any): Field {
  return typeof urlParam === 'string' && urlParam.toLowerCase() === 'output' ? Field.OUTPUT : Field.INPUT
}

const ENS_NAME_REGEX = /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?$/
const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/
function validatedRecipient(recipient: any): string | null {
  if (typeof recipient !== 'string') return null
  const address = isAddress(recipient)
  if (address) return address
  if (ENS_NAME_REGEX.test(recipient)) return recipient
  if (ADDRESS_REGEX.test(recipient)) return recipient
  return null
}

export function queryParametersToSwapState(parsedQs: ParsedQs, chainId: number = 56): LimitOrderState {
  let inputCurrency = parseCurrencyFromURLParameter(parsedQs.inputCurrency)
  let outputCurrency = parseCurrencyFromURLParameter(parsedQs.outputCurrency)
  const typedValue = parseTokenAmountURLParameter(parsedQs.exactAmount)
  const independentField = parseIndependentFieldURLParameter(parsedQs.exactField)

  if (inputCurrency === '' && outputCurrency === '' && typedValue === '' && independentField === Field.INPUT) {
    // Defaults to having the native currency selected
    if (chainId == ChainId.GÖRLI) {
      inputCurrency = '0x4a631e9117133c5f87fd9c051FE67E4f90575faB'
      outputCurrency = '0x3fA9eFFEa5Dfbf68d5Db48FAE322Fe892D3750fF'
    }
    if (chainId == ChainId.BINANCE_TESTNET) {
      inputCurrency = '0xc61e8563d354c4870D3526361D8531d79CD5dfE3'
      outputCurrency = '0x035dDaeE2b88c2842f94DAfd77eA83BF79c7d348'
    }
    if (chainId == ChainId.BINANCE) {
      inputCurrency = '0x735fa792e731a2e8F83F32eb539841b7B72e6d8f'
      outputCurrency = '0xa4335da338ec4C07C391Fc1A9bF75F306adadc08'
    }
    if (chainId == ChainId.MAINNET) {
      inputCurrency = '0x735fa792e731a2e8F83F32eb539841b7B72e6d8f'
      outputCurrency = '0xa4335da338ec4C07C391Fc1A9bF75F306adadc08'
    }
  } else if (inputCurrency === outputCurrency) {
    // clear output if identical
    outputCurrency = ''
  }

  const recipient = validatedRecipient(parsedQs.recipient)

  return {
    [Field.INPUT]: {
      currencyId: inputCurrency === '' ? null : inputCurrency ?? null,
    },
    [Field.OUTPUT]: {
      currencyId: outputCurrency === '' ? null : outputCurrency ?? null,
    },
    typedValue,
    independentField,
    recipient,
  }
}
export function useDefaultsFromURLSearch(): LimitOrderState {
  const { chainId } = useWeb3React()
  const dispatch = useAppDispatch()
  const parsedQs = useParsedQueryString()

  const parsedLimitOrderState = useMemo(() => {
    return queryParametersToSwapState(parsedQs, chainId)
  }, [parsedQs, chainId])

  useEffect(() => {
    if (!chainId) return
    const inputCurrencyId = parsedLimitOrderState[Field.INPUT].currencyId ?? undefined
    const outputCurrencyId = parsedLimitOrderState[Field.OUTPUT].currencyId ?? undefined

    dispatch(
      replaceLimitOrderState({
        typedValue: parsedLimitOrderState.typedValue,
        field: parsedLimitOrderState.independentField,
        inputCurrencyId,
        outputCurrencyId,
        recipient: parsedLimitOrderState.recipient,
      })
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, chainId])

  return parsedLimitOrderState
}
