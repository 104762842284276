export const enum ChainId {
  Mainnet = 1,
  Goerli = 5,
  Binance = 56,
  Chapel = 97,
}
export const ETH_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'

export const LIMIT_ORDER_CORE: { [ChainId: number]: string } = {
  [ChainId.Mainnet]: '',
  [ChainId.Goerli]: '0xfE70F4704D41D7a4D107788C5b3AC08B1281D471',
  [ChainId.Binance]: '',
  [ChainId.Chapel]: '0x74eFb62D109c7Ba0FDa9BcEb3D5B53F26F8DD5a3',
}
export const LIMIT_ORDER_MODULE: { [ChainId: number]: string } = {
  [ChainId.Mainnet]: '',
  [ChainId.Goerli]: '0x193FCbae17B8ca67A82e679bec0D27c2EB2AbCA1',
  [ChainId.Binance]: '',
  [ChainId.Chapel]: '0xC73d3af3b95d6c100802eaF40E1B9e9403A35474',
}

export const GRAPH: { [ChainId: number]: string } = {
  [ChainId.Mainnet]: '',
  [ChainId.Goerli]: 'https://api.thegraph.com/subgraphs/name/aryzeofficial/pine_orders_goerli',
  [ChainId.Binance]: '',
  [ChainId.Chapel]: 'https://api.thegraph.com/subgraphs/name/aryzeofficial/pine_orders_chapel',
}

export const FACTORY_ADDRESS = '0x0Ce853Bcb17179Ef56eAC6a4D78AF71eAc13278F' // ARYZE_Factory
export const INIT_CODE_HASH = '42cf62e1532656d6022c54c7da0c6c0da57e5e588a5bbadc2d69498ecfaecccd' // ARYZE_Factory_Pair
