// import { Currency } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { ETH_ADDRESS } from 'components/LimitOrder/lib/Consts'
import { SupportedChainId } from 'constants/chains'
import { useFetchListCallback } from 'hooks/useFetchListCallback'
import useHttpLocations from 'hooks/useHttpLocations'
// import { useTokenInfoFromActiveList } from 'hooks/useTokenInfoFromActiveList'
import { useMemo } from 'react'
import { useCombinedActiveList } from 'state/lists/hooks'
import { WrappedTokenInfo } from 'state/lists/wrappedTokenInfo'

import EthereumLogo from '../../assets/images/ethereum-logo.png'
import BinanceLogo from '../../assets/svg/binance-logo.svg'
import CeloLogo from '../../assets/svg/celo_logo.svg'
import MaticLogo from '../../assets/svg/matic-token-icon.svg'
import { isCelo, nativeOnChain } from '../../constants/tokens'

type Network = 'ethereum' | 'arbitrum' | 'optimism' | 'binance'

function chainIdToNetworkName(networkId: SupportedChainId): Network {
  switch (networkId) {
    case SupportedChainId.MAINNET:
      return 'ethereum'
    case SupportedChainId.BINANCE:
      return 'binance'
    case SupportedChainId.ARBITRUM_ONE:
      return 'arbitrum'
    case SupportedChainId.OPTIMISM:
      return 'optimism'
    default:
      return 'ethereum'
  }
}

function getNativeLogoURI(chainId: SupportedChainId = SupportedChainId.MAINNET): string {
  switch (chainId) {
    case SupportedChainId.BINANCE:
    case SupportedChainId.BINANCE_TESTNET:
      return BinanceLogo
    case SupportedChainId.POLYGON:
    case SupportedChainId.POLYGON_MUMBAI:
      return MaticLogo
    case SupportedChainId.CELO:
    case SupportedChainId.CELO_ALFAJORES:
      return CeloLogo
    default:
      return EthereumLogo
  }
}
function getNativeSymbol(chainId: SupportedChainId = SupportedChainId.MAINNET): string {
  switch (chainId) {
    case SupportedChainId.BINANCE:
    case SupportedChainId.BINANCE_TESTNET:
      return 'BNB'
    case SupportedChainId.POLYGON:
    case SupportedChainId.POLYGON_MUMBAI:
      return 'MATIC'
    case SupportedChainId.CELO:
    case SupportedChainId.CELO_ALFAJORES:
    case SupportedChainId.GOERLI:
      return 'ETH'
    default:
      return 'BNB'
  }
}
function getNativeName(chainId: SupportedChainId = SupportedChainId.MAINNET): string {
  switch (chainId) {
    case SupportedChainId.BINANCE:
    case SupportedChainId.BINANCE_TESTNET:
      return 'Binance Smart Chain'
    case SupportedChainId.POLYGON:
    case SupportedChainId.POLYGON_MUMBAI:
      return 'Polygon'
    case SupportedChainId.CELO:
    case SupportedChainId.CELO_ALFAJORES:
    case SupportedChainId.GOERLI:
      return 'Ethereum'
    default:
      return 'Binance Smart Chain'
  }
}

function getTokenLogoURI(address: string, chainId: SupportedChainId = SupportedChainId.MAINNET): string | void {
  const networkName = chainIdToNetworkName(chainId)
  const networksWithUrls = [
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.MAINNET,
    SupportedChainId.OPTIMISM,
    SupportedChainId.BINANCE,
  ]
  if (networksWithUrls.includes(chainId)) {
    return `https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/${networkName}/assets/${address}/logo.png`
  }

  // Celo logo logo is hosted elsewhere.
  if (isCelo(chainId)) {
    if (address === nativeOnChain(chainId).wrapped.address) {
      return 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_CELO.png'
    }
  }
}

export default function useCurrencyLogoURIs(currency: any): string[] {
  const locations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)
  const tokens = useCombinedActiveList()
  const address = currency?._checksummedAddress || currency?.address
  const { chainId } = useWeb3React()

  // if (currency?.list == undefined) currency = token

  return useMemo(() => {
    const logoURIs = [...locations]

    if (chainId && address) {
      // console.log(tokens[chainId])

      const uri = tokens[chainId][address.toLowerCase()]?.token?.tokenInfo?.logoURI
      // console.log(uri)

      if (uri) logoURIs.push(uri)
    }
    if (currency) {
      if (currency?.list?.logoURI) logoURIs.push(currency.list.logoURI)
      if (currency.isNative || address.toLowerCase() == ETH_ADDRESS.toLowerCase()) {
        logoURIs.push(getNativeLogoURI(currency.chainId))
      } else if (currency.isToken) {
        // const logoURI = getTokenLogoURI(currency.address, currency.chainId)
        // if (logoURI) {
        //   logoURIs.push(logoURI)
        // }
      }
    }
    return logoURIs
  }, [currency, locations, chainId])
}
export function useCurrencyNameAndSymbol(address: any): { name: string; symbol: string } {
  const { chainId } = useWeb3React()
  const tokens = useCombinedActiveList()

  return useMemo(() => {
    var res = { name: '', symbol: '' }
    if (chainId && address) {
      const name = tokens[chainId][address.toLowerCase()]?.token?.tokenInfo?.name
      const symbol = tokens[chainId][address.toLowerCase()]?.token?.tokenInfo?.symbol
      if (address.toLowerCase() == ETH_ADDRESS.toLowerCase()) {
        res.name = getNativeName(chainId)
        res.symbol = getNativeSymbol(chainId)
      }
      if (name) res.name = name
      if (symbol) res.symbol = symbol
    }

    return res
  }, [address, tokens])
}
